import { hostname } from "./api";
import jwt_decode from "jwt-decode";

let refreshTokenTimeout;
let isRefreshing = false;
let pendingRequests = [];
let refreshAttempts = 0;
const MAX_REFRESH_ATTEMPTS = 3;
const REFRESH_MARGIN_MS = 5 * 60 * 1000; // 5 minutos antes de la expiración

// Función para imprimir información de depuración sobre el token
const logTokenInfo = (token, prefix = "") => {
  try {
    if (!token) {
      console.log(`${prefix} Token no disponible`);
      return;
    }
    
    const decoded = jwt_decode(token);
    const expirationTime = new Date(decoded.exp * 1000);
    const timeRemaining = expirationTime.getTime() - Date.now();
    
    console.log(`${prefix} Token info:`, {
      sub: decoded.sub,
      exp: expirationTime.toLocaleString(),
      timeRemaining: `${Math.round(timeRemaining/1000)} segundos (${Math.round(timeRemaining/1000/60)} minutos)`,
      roles: decoded.roles
    });
  } catch (error) {
    console.error(`${prefix} Error al decodificar token:`, error);
  }
};

const authProvider = (tokenOps) => {
  // Verificar el estado del token al iniciar
  const checkInitialToken = () => {
    const accessToken = tokenOps.account?.accessToken;
    const refreshToken = tokenOps.root?.refreshToken;
    
    console.log('[Auth] Verificando tokens iniciales');
    
    if (accessToken) {
      logTokenInfo(accessToken, '[Auth] Token de acceso inicial:');
      
      try {
        const decoded = jwt_decode(accessToken);
        const expirationTime = decoded.exp * 1000;
        const currentTime = Date.now();
        
        if (currentTime < expirationTime) {
          // El token aún es válido, configurar el temporizador para renovarlo
          startRefreshTokenTimer(accessToken);
        } else if (refreshToken) {
          // El token ha expirado pero tenemos un refresh token, intentar renovar inmediatamente
          console.log('[Auth] Token expirado al iniciar, intentando renovar');
          setTimeout(() => refreshAccessToken(), 0);
        }
      } catch (error) {
        console.error('[Auth] Error al verificar token inicial:', error);
        if (refreshToken) {
          setTimeout(() => refreshAccessToken(), 0);
        }
      }
    } else if (refreshToken) {
      // No hay token de acceso pero sí hay refresh token, intentar renovar
      console.log('[Auth] No hay token de acceso pero sí refresh token, intentando renovar');
      setTimeout(() => refreshAccessToken(), 0);
    }
  };
  
  // Ejecutar verificación inicial
  setTimeout(checkInitialToken, 500);

  const startRefreshTokenTimer = (accessToken) => {
    // Limpiar cualquier temporizador existente
    if (refreshTokenTimeout) {
      clearTimeout(refreshTokenTimeout);
    }
    
    try {
      const jwtToken = jwt_decode(accessToken);
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - REFRESH_MARGIN_MS;
      
      console.log(`[Auth] Token expira el ${expires.toLocaleString()}`);
      console.log(`[Auth] Configurando temporizador para renovar el ${new Date(Date.now() + timeout).toLocaleString()} (en ${Math.round(timeout/1000/60)} minutos)`);
      
      // No configurar temporizadores negativos
      if (timeout <= 0) {
        console.warn('[Auth] Token ya cerca de expiración, renovando inmediatamente');
        refreshAccessToken();
        return;
      }
      
      refreshTokenTimeout = setTimeout(() => {
        console.log('[Auth] Temporizador activado, iniciando renovación de token');
        refreshAccessToken();
      }, timeout);
    } catch (error) {
      console.error('[Auth] Error al configurar temporizador de renovación:', error);
    }
  };

  const refreshAccessToken = async () => {
    if (isRefreshing) {
      console.log('[Auth] Renovación ya en progreso, esperando finalización');
      return new Promise((resolve, reject) => {
        pendingRequests.push({ resolve, reject });
      });
    }

    isRefreshing = true;
    const refreshToken = tokenOps.root?.refreshToken;
    
    if (!refreshToken) {
      console.error('[Auth] No hay refresh token disponible');
      isRefreshing = false;
      tokenOps.removeRootToken();
      return Promise.reject(new Error("Sesión expirada. Por favor inicie sesión nuevamente."));
    }

    try {
      console.log('[Auth] Intentando renovar token de acceso');
      const response = await fetch(`${hostname}/auth/refresh`, {
        method: "POST",
        body: JSON.stringify({ refreshToken }),
        headers: new Headers({ "Content-Type": "application/json" }),
      });

      if (!response.ok) {
        console.warn(`[Auth] Renovación falló con estado: ${response.status}`);
        throw new Error(response.statusText || 'Error al renovar token');
      }

      const auth = await response.json();
      console.log('[Auth] Token renovado exitosamente');
      logTokenInfo(auth.accessToken, '[Auth] Nuevo token:');
      
      // Reiniciar intentos de renovación al tener éxito
      refreshAttempts = 0;
      
      tokenOps.setRootToken(auth);
      startRefreshTokenTimer(auth.accessToken);

      pendingRequests.forEach((req) => req.resolve(auth.accessToken));
      pendingRequests = [];
      isRefreshing = false;
      return auth.accessToken;
    } catch (error) {
      console.error('[Auth] Error al renovar token:', error);
      
      // Implementar lógica de reintento
      refreshAttempts++;
      isRefreshing = false;
      
      if (refreshAttempts < MAX_REFRESH_ATTEMPTS) {
        console.log(`[Auth] Intento de reintento ${refreshAttempts}/${MAX_REFRESH_ATTEMPTS} en 5 segundos`);
        
        // Resolver solicitudes pendientes con token actual para evitar bloqueos
        const currentToken = tokenOps.account?.accessToken;
        if (currentToken) {
          pendingRequests.forEach((req) => req.resolve(currentToken));
          pendingRequests = [];
        } else {
          pendingRequests.forEach((req) => req.reject(error));
          pendingRequests = [];
        }
        
        // Intentar nuevamente después de un retraso
        setTimeout(refreshAccessToken, 5000);
        return currentToken || Promise.reject(error);
      } else {
        console.error('[Auth] Máximo de intentos de renovación alcanzado, cerrando sesión');
        pendingRequests.forEach((req) => req.reject(error));
        pendingRequests = [];
        tokenOps.removeRootToken();
        return Promise.reject(error);
      }
    }
  };

  const fetchWithToken = async (url, options = {}) => {
    try {
      const accessToken = await checkAndRenewToken();
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(url, options);

      if (response.status === 403) {
        console.log('[Auth] Respuesta 403, intentando renovar token');
        await refreshAccessToken();
        const retryOptions = {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${tokenOps.account.accessToken}`,
          },
        };
        return fetch(url, retryOptions);
      }

      return response;
    } catch (error) {
      console.error("[Auth] Error en fetchWithToken:", error);
      throw error;
    }
  };

  const checkAndRenewToken = async () => {
    const accessToken = tokenOps.account?.accessToken;
    if (!accessToken) {
      return Promise.reject(new Error("No se encontró token de acceso"));
    }

    try {
      const token = jwt_decode(accessToken);
      const currentTime = Date.now();
      const expirationTime = token.exp * 1000;
      const timeRemaining = expirationTime - currentTime;
      
      if (timeRemaining < REFRESH_MARGIN_MS) {
        console.log(`[Auth] Token expira en ${Math.round(timeRemaining/1000)} segundos, renovando`);
        return refreshAccessToken();
      }
      
      return accessToken;
    } catch (error) {
      console.error('[Auth] Error al verificar token:', error);
      return refreshAccessToken();
    }
  };

  return {
    login: async ({ username, password, remember = false }) => {
      try {
        console.log('[Auth] Intentando iniciar sesión');
        const response = await fetch(`${hostname}/auth/access`, {
          method: "POST",
          body: JSON.stringify({ email: username, password, remember }),
          headers: new Headers({ "Content-Type": "application/json" }),
        });

        if (!response.ok) {
          throw new Error(response.statusText || 'Error al iniciar sesión');
        }

        const auth = await response.json();
        tokenOps.setRootToken(auth);

        const decodedToken = jwt_decode(auth.accessToken);
        if (!decodedToken.roles.includes("ADMIN")) {
          throw new Error("Roles incorrectos");
        }

        console.log('[Auth] Inicio de sesión exitoso');
        logTokenInfo(auth.accessToken, '[Auth] Token de inicio de sesión:');
        
        // Reiniciar intentos de renovación al iniciar sesión exitosamente
        refreshAttempts = 0;
        startRefreshTokenTimer(auth.accessToken);
      } catch (error) {
        console.error("[Auth] Error en inicio de sesión:", error);
        throw error;
      }
    },

    fetchWithToken, // Usar este método para solicitudes protegidas

    checkAuth: async () => {
      const accessToken = tokenOps.account?.accessToken;
      if (!accessToken) {
        console.log('[Auth] No se encontró token de acceso durante checkAuth');
        return Promise.reject();
      }

      try {
        const token = jwt_decode(accessToken);
        const currentTime = Date.now();
        const expirationTime = token.exp * 1000;
        const timeRemaining = expirationTime - currentTime;
        
        console.log(`[Auth] Token expira en ${Math.round(timeRemaining/1000)} segundos (${Math.round(timeRemaining/1000/60)} minutos)`);
        
        if (timeRemaining < REFRESH_MARGIN_MS) {
          console.log('[Auth] Token cerca de expiración durante checkAuth, renovando');
          try {
            await refreshAccessToken();
          } catch (error) {
            console.error('[Auth] Error al renovar token durante checkAuth:', error);
            
            // Solo cerrar sesión si hemos excedido el máximo de intentos
            if (refreshAttempts >= MAX_REFRESH_ATTEMPTS) {
              console.warn('[Auth] Máximo de intentos de renovación alcanzado durante checkAuth, cerrando sesión');
              tokenOps.removeRootToken();
              return Promise.reject();
            }
            
            // De lo contrario, permitir que el usuario continúe con el token actual
            // Esto evita la interrupción de la sesión si el servidor de renovación está temporalmente caído
            console.log('[Auth] Continuando con token actual a pesar del error de renovación');
            return Promise.resolve();
          }
        }

        return Promise.resolve();
      } catch (error) {
        console.error('[Auth] Error en checkAuth:', error);
        return Promise.reject();
      }
    },

    getPermissions: () => {
      const roles = tokenOps.root?.data?.roles;
      if (!roles) {
        console.warn('[Auth] No hay roles disponibles');
        return Promise.reject(new Error("No hay roles disponibles"));
      }
      return Promise.resolve({ roles });
    },

    checkError: async (error) => {
      const status = error.status;

      if (status === 401 || status === 403) {
        console.log(`[Auth] Error ${status} recibido, intentando renovar token`);
        try {
          await refreshAccessToken();
        } catch (refreshError) {
          console.error('[Auth] Error al renovar token después de error de autenticación:', refreshError);
          
          // Solo cerrar sesión si hemos excedido el máximo de intentos
          if (refreshAttempts >= MAX_REFRESH_ATTEMPTS) {
            console.warn('[Auth] Máximo de intentos de renovación alcanzado después de error de autenticación, cerrando sesión');
            tokenOps.removeRootToken();
            return Promise.reject();
          }
          
          // De lo contrario, permitir que el usuario continúe
          console.log('[Auth] Continuando a pesar del error de renovación');
        }
      }
      return Promise.resolve();
    },

    logout: () => {
      console.log('[Auth] Cerrando sesión');
      clearTimeout(refreshTokenTimeout);
      refreshAttempts = 0;
      tokenOps.removeRootToken();
      return Promise.resolve();
    },
  };
};

export default authProvider;